@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{

    .App{
        @apply p-2 xl:p-0;
    }

    .bg-blur{
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
    }
    h1, h2, h3, h4, h5{
        @apply font-display font-bold italic;
    }
    h2{
        @apply text-2xl;
    }
    h3{
        @apply text-xl;
    }
    h6{
        @apply text-sm text-lightgreen font-bold;
    }

    a{
        @apply text-lightgreen;
    }
    a:hover{
        @apply underline;
    }

    p{
        @apply my-4;
    }
    p.lead{
        @apply mt-0 text-2xl;
    }

    ul{
        @apply my-4 pl-5;
        list-style-type: disc;
    }
    li{
        @apply my-1;
    }

    blockquote{
        @apply text-2xl italic my-4 py-1 border-l-4 border-lightgreen mx-auto;
        padding-left: 2.4em;
        position:relative;
    }
    blockquote::before{
        @apply text-7xl font-display text-lightgreen;
        content: "\201C";
        position: absolute;
        left: 0.2em;
        /* top: -0.43em; */
        top: -0.18em;
    }
    /* last word gets the end quote */
    blockquote>div{
        position:relative;
    }
    blockquote>div::after{
        @apply text-7xl font-display text-lightgreen;
        content: "\201D";
        position: relative;
        top: 0.62em;
        line-height: 0;
        display: inline-block;
        margin-left: 0.1em;
    }



    a.btn{
        @apply inline-block px-4 py-2 border rounded;
        text-decoration:none;
        transition:background-color 0.2s, border-color 0.2s, color 0.2s;
    }


    .portfolio-bg{
        background:white;
        background-attachment:fixed;
        background-size:cover;
        background-position:50% 50%;
        box-shadow: 0 4px 32px rgba(0,0,0,0.6);
    }


    .gallery-preview{
        cursor:pointer;
    }
    .gallery-preview .first{
        transition:box-shadow 0.2s;
    }
    .gallery-preview .left{
        left:-20%;
    }
    .gallery-preview .right{
        right:-20%;
    }



    #lightbox{
        transition: opacity 0.2s;
    }





    #threedpe .main{
        background-color:rgba(33,33,33,0.9);
    }
    #threedpe  a.btn{
        border-color: #ff3000;
        color:#ff3000;
    }
    #threedpe  a.btn:hover{
        color:white;
        background-color:#ff3000;
        border-color: #ff3000;
    }
    #threedpe .gallery-preview .first{
        box-shadow: 0 0 16px #ff3000;
    }
    #threedpe .gallery-preview:hover .first{
        box-shadow: 0 0 32px  #ff3000;
    }




    #luke .main{
        color:rgb(20, 45, 68);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
        background-color: rgba(255,255,255,.9);
    }
    #luke  a.btn{
        border-color: rgb(67,126,190);
        color: rgb(67,126,190);
    }
    #luke  a.btn:hover{
        color:white;
        background-color:rgb(67,126,190);
        border-color: rgb(67,126,190);
    }
    #luke .gallery-preview .first{
        box-shadow: 0 0 16px rgb(67,126,190);
    }
    #luke .gallery-preview:hover .first{
        box-shadow: 0 0 32px  rgb(67,126,190);
    }






    #majestic .main{
        background:white;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    }
    #majestic  a.btn{
        border-color: rgb(20,123,113);
        color: rgb(20,123,113);
    }
    #majestic  a.btn:hover{
        color:white;
        background-color:rgb(20,123,113);
        border-color: rgb(20,123,113);
    }
    #majestic .gallery-preview .first{
        box-shadow: 0 0 16px rgb(20,123,113);
    }
    #majestic .gallery-preview:hover .first{
        box-shadow: 0 0 32px  rgb(20,123,113);
    }




    #workspace .main{
        background:white;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    }
    #workspace  a.btn{
        border-color: rgb(13,51,109);
        color: rgb(13,51,109);
    }
    #workspace  a.btn:hover{
        color:white;
        background-color:rgb(13,51,109);
        border-color: rgb(13,51,109);
    }
    #workspace .gallery-preview .first{
        box-shadow: 0 0 16px rgb(13,51,109);
    }
    #workspace .gallery-preview:hover .first{
        box-shadow: 0 0 32px  rgb(13,51,109);
    }



}
